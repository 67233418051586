
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { QTable, QTr, QTh } from 'quasar';

import { getBundles } from '@/services/domain-checker.service';
import { FindDomainInCartGetter } from '@/store/getters.d';
import { Domain, DomainBundle } from '../dc.types';
import DomainResultItem from './DomainResultItem.vue';

@Component({
  components: {
    DomainResultItem,
    // fix ref: https://github.com/quasarframework/quasar/issues/6277#issuecomment-652391838
    QTable: (QTable as unknown) as VueConstructor<Vue>,
    QTr,
    QTh,
  },
  computed: {
    ...mapGetters(['dcFindDomainInCart']),
  },
})
export default class DomainsResultTable extends Vue {
  @Prop({ required: true })
  readonly domains!: Domain[];
  @Prop({ default: () => [] })
  readonly showBundleForDomains!: string[];
  @Prop(Boolean)
  readonly hideHeader!: boolean;
  @Prop(Boolean)
  readonly dark!: boolean;

  // Vuex
  dcFindDomainInCart!: FindDomainInCartGetter;

  domainBundles: DomainBundle[] = [];
  columns = [
    {
      name: 'domain',
      align: 'left',
    },
    {
      name: 'period',
      align: 'left',
    },
    {
      name: 'price',
      align: 'left',
    },
    {
      name: 'action',
      align: 'right',
    },
  ];

  mounted() {
    if (this.showBundleForDomains.length) {
      getBundles(this.showBundleForDomains).then(bundles => {
        this.domainBundles = bundles;
      });
    }
  }

  get tableData() {
    return Array.from(this.domains, (domain: Domain) => {
      return {
        domain,
        bundle: this.getBundleFor(domain.name),
      };
    });
  }

  getBundleFor(domainName: string): DomainBundle | undefined {
    return this.domainBundles.find(
      (bundle: DomainBundle) => bundle.mainDomain.name === domainName
    );
  }

  isShownBundleForDomain(domainName: string): boolean {
    const bundle = this.showBundleForDomains.includes(domainName)
      ? this.getBundleFor(domainName)
      : undefined;

    return !!(
      bundle &&
      // TODO: fix on backend, endpoint should return only free domains without errors
      bundle.mainDomain.availableForPurchase &&
      bundle.discountedDomain.availableForPurchase &&
      !bundle.mainDomain.error &&
      !bundle.discountedDomain.error
    );
  }
}
