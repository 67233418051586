
import {Component, Prop, Vue} from 'vue-property-decorator';

// Components
import {QTd, QTr} from 'quasar';

import WsMessage from '@WS_Components/WsMessage.vue';
import WsPeriod from '@WS_Components/WsPeriod.vue';
import PriceCell from '@/components/domain-checker/resultsTable/PriceCell.vue';
import Ctas from '@/components/domain-checker/resultsTable/Ctas.vue';
import BundleTitle from '@/components/domain-checker/resultsTable/BundleTitle.vue';
import DomainTitle from '@/components/domain-checker/resultsTable/DomainTitle.vue';
import {IsDomainLoadingGetter} from '@/store/getters.d';
import { Theme } from '@loopia-group/services';

import {rmTld} from '@/filters';

import {mapGetters} from 'vuex';

import {Domain, DomainBundle, Price,} from '@/components/domain-checker/dc.types';
import {useCartItemsStore} from '@/store/cartItemsStore';

@Component({
  components: {
    QTr,
    QTd,
    DomainTitle,
    BundleTitle,
    Ctas,
    PriceCell,
    WsMessage,
    WsPeriod,
  },

  filters: { rmTld },

  computed: {
    ...mapGetters(['cartDomains', 'cartTransferDomains', 'isDomainLoading']),
  },
})
export default class DomainResultItem extends Vue {
  @Prop({ default: () => ({}) }) props!: any;
  @Prop(Boolean) readonly dark!: boolean;
  @Prop(Boolean) isBundle!: boolean;

  // store getters
  readonly cartDomains!: string[];
  readonly cartTransferDomains!: string[];
  isDomainLoading!: IsDomainLoadingGetter;
  Theme = Theme;

  get apiError(): boolean {
    return this.$messageService.hasMessages(this.path);
  }

  get isLoading() {
    return this.bundle
      ? this.isDomainLoading(this.bundle.mainDomain) ||
          this.isDomainLoading(this.bundle.discountedDomain)
      : this.isDomainLoading(this.domain);
  }

  get error() {
    return this.bundle
      ? this.bundle.mainDomain.error || this.bundle.discountedDomain.error
      : this.domain?.error;
  }

  get hasError() {
    return !!this.error;
  }

  get hasAnyError() {
    return this.hasError || this.apiError;
  }

  get path() {
    if (this.domain) {
      return `domainChecker.${this.domain.name}`;
    } else if (this.bundle) {
      return `domainChecker.${this.bundle.mainDomain.name}.${this.bundle.mainDomain.name}`;
    }
    return 'domainChecker.general';
  }

  get period() {
    return this.bundle ? this.bundle.mainDomain.period : this.domain?.period;
  }

  get prolongPeriod() {
    return this.bundle
      ? this.bundle.mainDomain.prolongPeriod
      : this.domain?.prolongPeriod;
  }

  get price(): Price | null {
    if (this.domain) {
      return this.domain.price || null;
    }
    // else is bundle

    const primaryPrice = this.bundle?.mainDomain?.price;
    const secondaryPrice = this.bundle?.discountedDomain?.price;

    if (!primaryPrice || !secondaryPrice) {
      return null;
    }

    const primaryMarketingPrice = primaryPrice.marketingPrice || 0;
    const secondaryMarketingPrice = secondaryPrice.marketingPrice || 0;
    const primaryActualPrice = primaryPrice.actualPrice || 0;
    const secondaryActualPrice = secondaryPrice.actualPrice || 0;

    return {
      actualPrice: primaryActualPrice + secondaryActualPrice,
      marketingPrice: primaryMarketingPrice + secondaryMarketingPrice || null,
      transferPrice: null, // not needed for bundle
      prolongPrice: null, // not needed for bundle
    } as Price;
  }

  get domain(): Domain | undefined {
    return !this.isBundle ? this.props.row?.domain : undefined;
  }

  get bundle(): DomainBundle | undefined {
    if (!this.isBundle) {
      return undefined;
    }

    if (this.bundleAvailable) {
      return this.props.row?.bundle;
    } else {
      return undefined;
    }
  }

  get bundleAvailable() {
    const bundle = this.props.row?.bundle;
    return (
      bundle &&
      bundle.mainDomain.availableForPurchase &&
      bundle.discountedDomain.availableForPurchase
    );
  }

  get isInCart() {
    return this.domain
      ? this.cartDomains.includes(this.domain.name)
      : this.cartDomains.includes(this.bundle?.mainDomain?.name || '') &&
          this.cartDomains.includes(this.bundle?.discountedDomain?.name || '');
  }

  get highlighted() {
    return this.isInCart && !this.apiError;
  }

  get isTransferInCart() {
    const itemStore = useCartItemsStore();
    return !!itemStore.cartTransferDomains?.includes(this.domain?.name || '');
  }

  computePercentageDiscountForDomain(
    domain: Domain,
    domain2?: Domain
  ): number | null {
    const domainPrice: Price | null = domain?.price || null;

    if (!domainPrice || !domain.availableForPurchase) {
      return null;
    }

    const { marketingPrice, actualPrice } = domainPrice;
    const marketingPrice2 = domain2?.price?.marketingPrice || 0;
    const actualPrice2 = domain2?.price?.actualPrice || 0;

    if (marketingPrice && actualPrice && actualPrice < marketingPrice) {
      return (
        ((marketingPrice + marketingPrice2 - (actualPrice + actualPrice2)) /
          (marketingPrice + marketingPrice2)) *
        100
      );
    }

    return null;
  }

  get forSale(): boolean {
    return (
      (this.domain?.availableForPurchase || this.bundleAvailable) &&
      !this.isInCart &&
      !this.apiError
    );
  }

  get bundlePercentageDiscountLabel() {
    if (!this.bundle) {
      return null;
    }

    const discount = this.computePercentageDiscountForDomain(
      this.bundle.mainDomain,
      this.bundle.discountedDomain
    );
    return discount && discount > 0 ? Math.round(discount) + '%' : null;
  }

  get percentageDiscountLabel() {
    const discount = this.domain
      ? this.computePercentageDiscountForDomain(this.domain)
      : null;

    return discount && discount > 0 ? '-' + Math.round(discount) + '%' : null;
  }

  buy() {
    if (!this.forSale) {
      return;
    }
    (this.$refs.ctas as any)?.buy();
  }
}
