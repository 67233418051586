
import { Component, Vue, Prop } from 'vue-property-decorator';

// Components
import { QTd } from 'quasar';
import { simpleWrapperCmp } from '@loopia-group/utils';
import WsIcon from '@WS_Components/WsIcon.vue';

import { DomainBundle } from '@/components/domain-checker/dc.types';

import { rmTld } from '@/filters';

@Component({
  components: {
    QTd,
    WsIcon,
  },

  filters: { rmTld },
})
export default class BundleTitle extends Vue {
  @Prop({ default: () => ({}) }) props!: any;

  readonly mobile!: boolean;

  QTd = QTd;
  simpleWrapperCmp = simpleWrapperCmp();

  get bundle(): DomainBundle {
    return this.props.row?.bundle;
  }

  get percentageDiscount(): string | null {
    const {
      marketingPrice = 0,
      actualPrice,
    } = this.bundle!.discountedDomain!.price!;

    if (actualPrice === 0) {
      return '100%';
    }

    if (actualPrice < marketingPrice!) {
      const discount =
        ((marketingPrice! - actualPrice) / marketingPrice!) * 100;
      return `${Math.round(discount)}%`;
    }
    return null;
  }
}
