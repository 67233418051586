var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-table',_vm._b({staticClass:"cmp-domains-result-table ws-table remove-scroll",class:{
    mobile: _vm.mobile,
    'not-mobile': _vm.desktop || _vm.tablet,
    'hide-header': _vm.hideHeader,
    dark: _vm.dark,
  },attrs:{"data":_vm.tableData,"wrap-cells":"","hide-bottom":"","grid":_vm.mobile,"columns":_vm.columns,"rows-per-page-options":[0],"pagination":{
    page: 1,
    rowsPerPage: 0, // 0 means all rows
  },"flat":"","hide-header":_vm.hideHeader},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-th',{key:"domain",staticClass:"col-th-domain",attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.$t('domain_checker.domain'))+" ")]),_c('q-th',{key:"period",staticClass:"col-th-period",attrs:{"auto-width":"","props":props}},[_vm._v(" "+_vm._s(_vm.$t('cart.col.period.title'))+" ")]),_c('q-th',{key:"price",staticClass:"col-th-price text-right",attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm.$t('domain_checker.price'))+" ")]),_c('q-th',{key:"action",staticClass:"col-th-action text-bold ws-text-dark",attrs:{"props":props}})],1)]}},{key:"body",fn:function(props){return [_c('q-tr',{staticClass:"spacer"}),_c('domain-result-item',{attrs:{"props":props,"dark":_vm.dark}}),(_vm.isShownBundleForDomain(_vm.get(props, 'row.domain.name')))?_c('q-tr',{staticClass:"spacer"}):_vm._e(),(_vm.isShownBundleForDomain(_vm.get(props, 'row.domain.name')))?_c('domain-result-item',{attrs:{"is-bundle":"","props":props,"dark":_vm.dark}}):_vm._e()]}},{key:"item",fn:function(props){return [_c('domain-result-item',{attrs:{"mobile":"","props":props,"dark":_vm.dark}}),(_vm.isShownBundleForDomain(_vm.get(props, 'row.domain.name')))?_c('domain-result-item',{attrs:{"is-bundle":"","props":props,"dark":_vm.dark,"mobile":""}}):_vm._e()]}}])},'q-table',_vm.$attrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }