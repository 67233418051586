
import {Component, Inject, Prop, Vue} from 'vue-property-decorator';
import {QTooltip} from 'quasar';
import {mapGetters} from 'vuex';

import {get} from 'lodash-es';
import WsButton from '@WS_Components/WsButton.vue';
import {StateMutations} from '@/store/const.enum';
import {Domain, DomainBundle} from '@/components/domain-checker/dc.types';
import DomainPopup from './DomainPopup.vue';
import DomainPopupTransfer from './DomainPopupTransfer.vue';
import {
  addBundleToCart,
  addDomainToCart,
  removeBundleFromCart,
  removeDomainFromCart,
} from '@/services/domain-checker.service';
import {Theme} from '@loopia-group/services';
import {CTAS_WORKFLOW} from './Ctas.enum';
import WsSpinner from '@WS_Components/WsSpinner.vue';

@Component({
  components: {
    WsSpinner,
    QTooltip,
    WsButton,
    DomainPopup,
    DomainPopupTransfer,
  },

  computed: {
    ...mapGetters(['cartDomains', 'loggedIn']),
  },
})
export default class Ctas extends Vue {
  @Prop()
    domain!: Domain;
  // OR:
  @Prop({ default: undefined }) bundle!: DomainBundle | undefined;
  @Prop(Boolean) readonly transferInCart!: boolean;
  @Prop(Boolean) readonly inCart!: boolean;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly error!: boolean;
  @Prop({ type: String, default: 'domainChecker.general' })
  readonly path!: string;
  @Inject({ from: 'onBoughtDomain', default: () => () => {} })
  readonly onBoughtDomain!: (d: Domain) => void;
  readonly loggedIn!: boolean;
  readonly cartDomains!: string[];
  Theme = Theme;

  isMyDomainPopup = false;
  showTransfer = false;

  CTAS_WORKFLOW = CTAS_WORKFLOW;

  get workflow() {
    switch (true) {
    case this.inCart:
      return CTAS_WORKFLOW.IN_CART;

    case this.availableForTransfer:
      return CTAS_WORKFLOW.MY_DOMAIN;

    case !this.bundle && !this.availableForPurchase:
      return this.isOwnerOfDomain ? CTAS_WORKFLOW.YOURS : CTAS_WORKFLOW.TAKEN;

    default:
      return CTAS_WORKFLOW.BUY;
    }
  }

  get shouldLogin(): boolean {
    return this.domainAlreadyAssigned && !this.loggedIn;
  }

  // Flags ==============
  get availableForPurchase(): boolean {
    return !!this.domain?.availableForPurchase;
  }

  get availableForTransfer(): boolean {
    return !!this.domain?.availableForTransfer;
  }

  get isOwnerOfDomain(): boolean {
    return !!this.domain?.isOwnerOfDomain;
  }

  get domainAlreadyAssigned(): boolean {
    return !!this.domain?.domainAlreadyAssigned;
  }

  public buy() {
    if (this.loading) {
      return;
    }
    this.bundle ? this.buyBundle() : this.buyDomain();
  }

  buyDomain(domain?: Domain) {
    domain = domain || this.domain;
    this.setDomainItemLoading(domain!.name, true);

    addDomainToCart(domain)
      .then(() => this.onBoughtDomain(domain!))
      .catch(this.handleCartApiError)
      .finally(() => this.setDomainItemLoading(domain?.name, false));
  }

  removeDomain() {
    const domain = this.domain;
    this.setDomainItemLoading(domain.name, true);
    removeDomainFromCart(domain)
      .catch(this.handleCartApiError)
      .finally(() => {
        this.setDomainItemLoading(domain.name, false);
      });
  }

  hidePopup() {
    (this.$refs.domainPopup as any).hide();
  }

  get mainDomainInCart(): boolean {
    return this.bundle
      ? this.cartDomains.includes(this.bundle.mainDomain.name)
      : false;
  }

  get discountedDomainInCart(): boolean {
    return this.bundle
      ? this.cartDomains.includes(this.bundle.discountedDomain.name)
      : false;
  }

  /*
    Domain Bundles
   */
  buyBundle() {
    // First or second domain in cart? Add to cart only the domain that is not in cart yet.
    if (this.mainDomainInCart && !this.discountedDomainInCart) {
      this.buyDomain(this.bundle!.discountedDomain);
      return;
    } else if (this.discountedDomainInCart && !this.mainDomainInCart) {
      this.buyDomain(this.bundle!.mainDomain);
      return;
    }

    this.setDomainItemLoading(this.bundle!.mainDomain.name, true);
    this.setDomainItemLoading(this.bundle!.discountedDomain.name, true);

    addBundleToCart(this.bundle!)
      .catch(this.handleCartApiError)
      .finally(() => {
        this.setDomainItemLoading(this.bundle?.mainDomain?.name, false);
        this.setDomainItemLoading(this.bundle?.discountedDomain?.name, false);
      });
  }

  removeBundle() {
    if (!this.bundle) {
      return;
    }

    this.setDomainItemLoading(this.bundle.mainDomain.name, true);
    this.setDomainItemLoading(this.bundle.discountedDomain.name, true);

    removeBundleFromCart(this.bundle)
      .catch(this.handleCartApiError)
      .finally(() => {
        this.setDomainItemLoading(this.bundle?.mainDomain?.name || '', false);
        this.setDomainItemLoading(
          this.bundle?.discountedDomain?.name || '',
          false
        );
      });
  }

  setDomainItemLoading(domainName: string = '', loading: boolean) {
    if (loading) {
      // clear messages when loading started
      this.$messageService.clearMessages(this.path);
    }

    this.$store.commit(StateMutations.SET_DOMAIN_ITEM_LOADING_STATE, {
      domainName,
      loading,
    });
  }

  handleCartApiError(error: any) {
    if (get(error, 'key') === 'exception.session.lock_timeout') {
      // ignore timeouts, do not show error message, error state of UI will be enough
      return;
    } else {
      this.$messageService.errorHandler(this.path)(error);
    }
  }
}
