var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.mobile)?_c('q-tr',{staticClass:"table-mode cmp-domain-result-item",class:{
    'ws-dark': _vm.dark || _vm.isTheme(_vm.Theme.LOOPIA),
    'ws-highlighted': _vm.highlighted,
    occupied: _vm.domain && !_vm.domain.availableForPurchase,
    'is-loading': _vm.isLoading,
    'for-sale': _vm.forSale,
    error: _vm.hasAnyError,
  },attrs:{"props":_vm.props},on:{"click":_vm.buy}},[(_vm.domain)?_c('domain-title',{staticClass:"td-domain",attrs:{"props":_vm.props,"in-cart":_vm.isInCart}}):(_vm.bundle)?_c('bundle-title',{staticClass:"td-domain",attrs:{"dark":_vm.dark,"highlighted":_vm.highlighted,"props":_vm.props}}):_vm._e(),(_vm.hasError)?[_c('q-td',{key:"period",staticClass:"td-period",attrs:{"props":_vm.props}},[_c('small',{staticClass:"table-item-status text-ws-error"},[_c('strong',[_vm._v(_vm._s(_vm.$t('domain_checker.error')))])])]),_c('q-td',{key:"price",staticClass:"td-price text-right text-ws-error q-pr-md",attrs:{"props":_vm.props,"colspan":"2"}},[_c('small',{staticClass:"q-pr-md"},[_vm._v(_vm._s(_vm.$t(_vm.error)))])])]:[(_vm.bundle || _vm.domain.availableForPurchase || _vm.isInCart)?[_c('q-td',{key:"period",staticClass:"td-period ws-text-primary",attrs:{"props":_vm.props}},[(!_vm.isTransferInCart || _vm.bundle)?_c('ws-period',{attrs:{"period":_vm.bundle ? _vm.bundle.mainDomain.period : _vm.domain.period}}):_vm._e()],1),_c('q-td',{key:"price",staticClass:"td-price text-right",attrs:{"props":_vm.props}},[_c('price-cell',{attrs:{"price":_vm.price,"period":_vm.period,"prolong-period":_vm.prolongPeriod,"transfer":_vm.isTransferInCart}})],1)]:_vm._e(),_c('q-td',{key:"action",staticClass:"td-action",attrs:{"props":_vm.props,"colspan":_vm.bundle || _vm.domain.availableForPurchase || _vm.isInCart ? 1 : 3}},[_c('ctas',{ref:"ctas",attrs:{"transfer-in-cart":_vm.domain ? _vm.isTransferInCart : false,"in-cart":_vm.isInCart,"loading":_vm.isLoading,"domain":_vm.domain,"bundle":_vm.bundle,"path":_vm.path,"error":_vm.hasAnyError}})],1)]],2):_c('div',{staticClass:"mobile cmp-domain-result-item",class:{
    'ws-dark': _vm.dark || _vm.isTheme(_vm.Theme.LOOPIA),
    'ws-highlighted': _vm.highlighted,
    occupied: _vm.domain && !_vm.domain.availableForPurchase,
    'is-loading': _vm.isLoading,
    'for-sale': _vm.forSale,
    error: _vm.hasAnyError,
  },on:{"click":_vm.buy}},[_c('div',{staticClass:"domain-info-col"},[(_vm.domain)?[_c('domain-title',{attrs:{"mobile":"","props":_vm.props,"in-cart":_vm.isInCart}}),(!_vm.domain.availableForPurchase)?_c('div',{key:"period",staticClass:"grid-item-status text-uppercase",class:{ 'text-ws-error': _vm.hasError },attrs:{"props":_vm.props}},[_vm._v(" "+_vm._s(_vm.hasError ? _vm.$t('domain_checker.error') : _vm.$t('domain_checker.occupied.short_status'))+" ")]):_c('div',[(!_vm.hasError)?_c('div',{key:"price",staticClass:"grid-item-price",attrs:{"props":_vm.props}},[_c('price-cell',{attrs:{"price":_vm.price,"period":_vm.period,"prolong-period":_vm.prolongPeriod,"transfer":_vm.isTransferInCart}})],1):_c('div',{key:"price",staticClass:"grid-item-price",attrs:{"props":_vm.props}},[_vm._v(" "+_vm._s(_vm.$t(_vm.error))+" ")])])]:(_vm.bundle)?[_c('div',{staticClass:"domain-info-col"},[_c('bundle-title',{attrs:{"mobile":"","props":_vm.props}}),_c('div',{key:"price",staticClass:"grid-item-price",attrs:{"props":_vm.props}},[_c('price-cell',{attrs:{"price":_vm.price,"period":_vm.period,"prolong-period":_vm.prolongPeriod}})],1)],1)]:_vm._e()],2),_c('div',{key:"action",staticClass:"item-ctas flex items-center no-wrap"},[_c('ws-message',{staticClass:"q-mx-md",attrs:{"type":"alert","inline":"","path":_vm.path}},[_vm._v(" "+_vm._s(_vm.hasError ? _vm.$t(_vm.error) : '')+" ")]),(!_vm.hasError)?_c('ctas',{ref:"ctas",attrs:{"mobile":"","transfer-in-cart":_vm.domain ? _vm.isTransferInCart : false,"in-cart":_vm.isInCart,"loading":_vm.isLoading,"domain":_vm.domain,"bundle":_vm.bundle,"path":_vm.path,"error":_vm.hasAnyError}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }