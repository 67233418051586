
import { Component, Vue, Prop } from 'vue-property-decorator';

import { redirect } from '@loopia-group/utils';
import WsButton from '@WS_Components/WsButton.vue';
import WsInput from '@WS_Components/WsInput.vue';
import { StandaloneProduct } from '@/types';
import { getLoginUrl } from '../../../utilities';
import { Domain } from '@/components/domain-checker/dc.types';
import config from '@/services/config.service';
import { addToCart } from '@/services/cart/cart-api.service';
import axios from '@/services/network.service';
import {ROUTENAMES} from '../../../const.enum';

@Component({
  components: {
    WsInput,
    WsButton,
  },
})
export default class DomainPopupTransfer extends Vue {
  @Prop()
  readonly domain!: Domain;
  @Prop(Boolean)
  readonly shouldLogin!: boolean;

  eppCodeValue = '';
  loading = false;
  transferUrl = config.transferUrl;
  isEppCodeRequired = this.domain.isEppCodeForTransferRequired;


  mounted() {
    this.getTransferInfoUrl();
  }

  get transKey() {
    return this.isEppCodeRequired
      ? this.$t('domain_checker.transfer.description')
      : this.$t('domain_checker.transfer.description_without_eppCode');
  }

  async getTransferInfoUrl() {
    try {
      const resp = await axios.get('knowledge-base/article/domain-transfer');
      this.transferUrl = resp.data.url;
    } catch (err) {
      this.$messageService.errorHandler(
        'domain-popup-transfer.knowlage-base.link'
      )(err);
    }
  }

  get transferPrice(): number {
    return this.domain?.price?.transferPrice || 0;
  }

  get priceLabel() {
    return this.transferPrice > 0
      ? this.$t('domain_checker.transfer.price', {
        price: this.$n(this.transferPrice, 'currency'),
      })
      : this.$t('domain_checker.transfer.free');
  }

  addTransferToCart() {
    this.loading = true;

    const product = {
      code: 'domain-transfer',
      name: `.${this.domain.tld} doména`,
      sellType: 'direct',
      price: {
        vatExcluded: this.domain.price?.transferPrice,
      },
      properties: {
        domain: this.domain.name,
        period: this.domain.period,
        eppCode: this.eppCodeValue,
      },
    } as StandaloneProduct;

    addToCart(product)
      .then(() => {
        this.$emit('hide-popup'); // done
      })
      .catch(this.$messageService.errorHandler('transfer.eppCodeValue'))
      .finally(() => (this.loading = false));
  }

  triggerLogin() {
    redirect(getLoginUrl(this.$router, ROUTENAMES.DOMAIN_CHECKER));
    this.$emit('hide-popup');
  }
}
