
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QTd, QTooltip } from 'quasar';

import WsBadge from '@WS_Components/WsBadge.vue';
import { rmTld } from '@/filters';
import { Domain, Price } from '@/components/domain-checker/dc.types';
import PremiumBadge from '@/components/domain-checker/PremiumBadge.vue';

@Component({
  components: {
    PremiumBadge,
    QTd,
    QTooltip,
    WsBadge,
  },

  filters: { rmTld },
})
export default class DomainTitle extends Vue {
  @Prop({ default: () => ({}) }) props!: any;
  @Prop(Boolean) readonly inCart!: boolean;

  get domain(): Domain | undefined {
    return this.props.row?.domain;
  }

  get percentageDiscount(): string | null {
    const domainPrice: Price | null = this.domain!.price || null;

    if (!domainPrice || !this.domain!.availableForPurchase) {
      return null;
    }

    const { marketingPrice, actualPrice } = domainPrice;

    if (marketingPrice && actualPrice && actualPrice < marketingPrice) {
      const discount = ((marketingPrice - actualPrice) / marketingPrice) * 100;
      return discount && discount > 0 ? '-' + Math.round(discount) + '%' : null;
    }

    return null;
  }

  get showOccupied(): boolean {
    return (
      !this.domain?.availableForPurchase && !this.domain?.error && !this.inCart
    );
  }
}
